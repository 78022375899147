import cx from 'classnames';
import React from 'react';
import styles from './content.module.scss';

const ProfileContent = ({ values, growthAreas, patience }) => (
  <div className={cx(styles.contentStyles)}>
    <h4 className={cx(styles.emoji)}> <span role="img">💖</span></h4>
    <h4>My personal values</h4>
    {(values || []).map(({ name: valueName, description }) => (
      <div>
        <p><strong>{valueName}</strong></p>
        <p>{description}</p>
      </div>
    ))}
    <div>
      <h4 className={cx(styles.emoji)}> <span role="img">🤓</span></h4>
    </div>
    <h4>Growth Areas</h4>
    {(growthAreas || []).map(({ text }) => (
      <div>
        <p>{text}</p>
      </div>
    ))}
    <h4 className={cx(styles.emoji)}> <span role="img">🧐</span></h4>
    <h4>What I don't have patience for</h4>
    {(patience || []).map(({ text }) => (
      <div>
        <p>{text}</p>
      </div>
    ))}
  </div>
);

export default ProfileContent;
