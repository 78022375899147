import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './sc-ft-hero.module.scss';

class ScrollableFeaturesHero extends Component {
  constructor(props) {
    super(props);
    this.setActive = this.setActive.bind(this);
    this.state = {
      active: props.tabs[0].hash,
    }
  }

  setActive(active) {
    this.setState({ active })
  }

  activeTab() {
    const { tabs } = this.props;
    const { active } = this.state;
    return active ? tabs.find(tab => tab.hash === active) : tabs[0];
  }

  render() {
    const { tabs } = this.props;
    const { active } = this.state;
    const activeTab = this.activeTab();
    return (
      <div className={cx(styles.hero)}>
        <div className={cx(styles.heroContent)}>
          <div className={cx(styles.navWrap)}>
            <nav>
              {_.map(tabs, (tab) => {
                const onClick = () => this.setActive(tab.hash);
                return (
                  <a
                    onMouseEnter={onClick}
                    onMouseDown={onClick}
                    key={tab.hash}
                    className={cx({[styles.active]: active === tab.hash})}
                  >
                    <span>{tab.emoji}</span> {tab.title}
                  </a>
              )})}
            </nav>
          </div>
          <div className={cx(styles.tabText)}>
            {_.map(tabs, (tab) => (
              <div key={`c${tab.hash}`} className={cx({[styles.active]: active === tab.hash})}>
                <h3 className={cx(styles.tabTitle)}><span>{tab.emoji}</span> {tab.title}</h3>
                <p><strong>{tab.emph}</strong> {tab.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ScrollableFeaturesHero.propTypes = {
  page: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string,
    emoji: PropTypes.string,
    title: PropTypes.string,
    emph: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

export default ScrollableFeaturesHero;
