import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Intercom from 'react-intercom';
import ScrollableFeaturesHero from '../../components/ScrollableFeaturesHero/ScrollableFeaturesHero';
import FancySection from '../../components/FancySection/FancySection';
import Hero from '../../components/Hero/Hero';
import ArrowRight from '../../assets/arrow-right.svg';
import PopupTrigger from '../../components/Popup/PopupTrigger';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import ProfileContent from '../../components/ProfileContent/ProfileContent';
import LookArrow from '../../assets/look-arrow2.svg';
import styles from './company.module.scss';
import { trackEvent } from '../../utils';
import Layout from '../../components/Layout';

const featureTabs = (valuesList) => [
  {
    emoji: '🙋',
    hash: 'resource',
    ...valuesList[0]
  },
  {
    emoji: '🛠',
    hash: 'resourceful',
    ...valuesList[1]
  },
  {
    emoji: '🎛',
    hash: 'growth',
    ...valuesList[2]
  },
  {
    emoji: '🔮',
    hash: 'long-term',
    ...valuesList[3]
  },
  {
    emoji: '🍾',
    hash: 'journey',
    ...valuesList[4]
  },
]

const popupProps = {
  popupContentStyle: {
    padding: 0,
  },
  size: 'xm',
};

const TeammateBubble = ({ img, name, role, onClick = () => {} }) => (
  <span onClick={onClick}>
    <div
      style={{ backgroundImage: `url(${img})` }}
      className={cx(styles.teamImg, styles[`img${name}`])}
      onClick={() => trackEvent('Org Chart Profile Image', { name })}
    />
    <div className={cx(styles.teamInfo)}>
      <div>
        <p><strong>{name}</strong></p>
        <p>{role}</p>
      </div>
    </div>
  </span>
)


export const CompanyPageTemplate = ({
  seo,
  title,
  subtitle,
  joinCta,
  joinHref,
  featuredImg,
  valuesTitle,
  values,
  growthHero,
  hash,
  team,
  collage,
  contact,
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={cx('homepage')}>
      <section className={cx(styles.team)}>
        <div style={{ textAlign: 'center' }}>
          <h1 className="mb-2">Ask us aaanything.*</h1>
          <p>*Seriously. Click on a profile to start a conversation with someone on our team</p>
        </div>
        <div className={cx('container', styles.teamContainer)}>
          <div className={cx(styles.bubblesContainer)}>
            { team.map(({img, name, role, profileContent = {}, noProfile }, i) => (
              <div className={cx(styles.teammate, styles[`teammate${name}`], { [styles.noProfile]: noProfile })}>
                { noProfile ? <TeammateBubble {...{ img, name, role }} />
                : (
                  <PopupTrigger
                    popupProps={popupProps}
                    Trigger={<TeammateBubble {...{ img, name, role }} />}
                  >
                    <div>
                      <ProfileHeader name={name} img={img} title={role} {...profileContent} />
                      <ProfileContent {...profileContent} />
                    </div>
                  </PopupTrigger>
                )}
              </div>
            ))}
          </div>

          <div className={cx(styles.teamname, styles.market)}>Market</div>
          <div className={cx(styles.teamname, styles.leadership)}>Leadership</div>
          <div className={cx(styles.teamname, styles.eng)}>Engineering</div>
          <div className={cx(styles.teamname, styles.dog)}>Doge</div>

          <div className={cx(styles.reportingLine, styles.kevinAustin)} />
          <div className={cx(styles.reportingLine, styles.austinMichelle)} />
          <div className={cx(styles.reportingLine, styles.jamesAustin)} />
          <div className={cx(styles.reportingLine, styles.austinTroy)} />
          <div className={cx(styles.reportingLine, styles.shawnTroy)} />
          <div className={cx(styles.reportingLine, styles.dylanTroy)} />
          <div className={cx(styles.reportingLine, styles.marcusEmily)} />

          <div className={cx(styles.reportingLine, styles.marcusTroy)} />
          <div className={cx(styles.reportingLine, styles.marcusAustin)} />
          <div className={cx(styles.reportingLine, styles.kevinOdin)} />
          <div className={cx(styles.reportingLine, styles.troyEmily)} />
          <div className={cx(styles.joinUs)} id='careers'>
            <div>
              <a href={joinHref} target='_blank'>
                <div className={cx(styles.joinCircle)}>
                  <p>Join us!</p>
                </div>
              </a>
              <div className={cx(styles.lookHere)}>
                <LookArrow />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className={cx(styles.hero)}>
        <div className={cx('container')} style={{ maxWidth: '600px' }}>
          <h1 className="">{ title }</h1>
          { subtitle && <p>{subtitle }</p> }
          <a className={cx('btn', 'btn-wide')} href={joinHref} target='_blank'>{joinCta} &nbsp;<ArrowRight style={{ height: '14px' }} /></a>
        </div>
      </section>

      <section className={cx(styles.featuredImg)} style={{ backgroundImage: `url(${featuredImg})` }} />

      <section>
        <div className={cx('container')} style={{ maxWidth: '720px' }}>
          <h1 className={cx('text-center')}>{valuesTitle}</h1>
          <ScrollableFeaturesHero page='company' activeHash={hash} tabs={featureTabs(values)} />
        </div>
      </section>

      <section className={cx(styles.collage)}>
        { collage.map(({img}, i) => (
          <div style={{ backgroundImage: `url(${img})` }} className={cx(styles[`img${i}`])} />
        ))}
      </section>



      <FancySection variant='1' img={growthHero.img}>
        <h1>{growthHero.title}</h1>
        <p className="big">{growthHero.subtitle}</p>
        <p>{growthHero.text}</p>
        <a className={cx('btn', 'btn-sm')} href={joinHref} target='_blank'>{joinCta} &nbsp;<ArrowRight style={{ height: '14px' }} /></a>
      </FancySection>

      <br />

      <Hero classNames={['textRight']} className={cx(styles.contact)} id="contact">
        <div className={cx('text')}>
          <h1 className='mb-1'><span>👋</span> {contact.title}</h1>
          <p><big><a style={{fontWeight: '400'}} href="mailto:hello@resource.io">hello@resource.io</a></big></p>

          <div className={cx('mt-2')}>
            <p className='mb-2'>Resource HQ</p>
            <p className={cx('address', 'mb-2')}>
              <big>
                383 Tehama St<br />
                San Francisco, CA 94103
              </big>
            </p>
            <p>
              <a style={{fontWeight: '400'}} href={contact.directionsLink}>Get Directions
                <ArrowRight className='svg-red' style={{height: '12px'}} />
              </a>
            </p>
          </div>
        </div>
      </Hero>
    </div>
    <script type="text/javascript">{`
        piAId = '824443';
        piCId = '1645';
        piHostname = 'pi.pardot.com';
        
        (function() {
          function async_load(){
            var s = document.createElement('script'); s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
          }
          if(window.attachEvent) { window.attachEvent('onload', async_load); }
          else { window.addEventListener('load', async_load, false); }
        })();
      `}
    </script>
  </Layout>
);

CompanyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

CompanyPageTemplate.defaultProps = {
  subtitle: null,
};

const CompanyPage = ({
  location
}) => (
  <StaticQuery
    query={graphql`
      query CompanyQuery {
        markdownRemark(frontmatter: { name: { eq: "company" } }) {
          frontmatter {
            templateKey
            seo {
              description
              title
            }
            title
            subtitle
            joinCta
            joinHref
            featuredImg
            valuesTitle
            values {
              title
              emph
              text
            }
            growthHero {
              title
              subtitle
              text
              img
            }
            team {
              img
              name
              role
              noProfile
              profileContent {
                calendarLink
                growthAreas {
                  text
                }
                patience {
                  text
                }
                values {
                  name
                  description
                }
              }
            }
            collage {
              img
            }
            contact {
              title
              directionsLink
            }
          }
        }
      }
    `}
    render={(
      data
    ) => {
      return (
        <span>
          <CompanyPageTemplate
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...data.markdownRemark.frontmatter}
            location={location}
          />
          <Intercom appID="rg0xc7fz" />
        </span>
    )
    }}
  />
);  

CompanyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default CompanyPage;
