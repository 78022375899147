import cx from 'classnames';
import React from 'react';
import styles from './header.module.scss';
import { trackEvent } from '../../utils';

const showIntercomClick = (name) => (() => {
  if (window.Intercom) {
    window.Intercom('showNewMessage', `Hey ${name},\n\n`);
  }
  trackEvent('Org Chart Profile Chat Clicked', { name });
});

const openCalendarClick = (name) => (() => {
  trackEvent('Org Chart Profile Schedule Calendar Clicked', { name });
  return true;
});

const ProfileHeader = ({name, img, title, calendarLink }) => (
  <div className={(styles.profileHeader)}>
    <div className={(styles.profileHat)}>
      <div className={(styles.contents)}>
        <div className={(styles.imageContents)}>
          <div className={(styles.profileImageWrapper)}>
            <div style={{ backgroundImage: `url(${img})` }} className={cx(styles.teamImg)} />
          </div>
          <div className={(styles.personInfo)}>
            <div className={(styles.name)}><strong>{name}</strong></div>
            <div className={(styles.title)}>{title}</div>
          </div>
        </div>
        <div>
          <div className={(styles.ctas)}>
            <a
              className={cx('btn', 'btn-wide', styles.profileHeaderCTA)}
              onClick={showIntercomClick(name)}
            >
              <span className={cx(styles.long)}>
                Chat with {name} 💬
              </span>
              <span className={cx(styles.short)}>
                Chat 💬
              </span>
            </a>
            {calendarLink && (
              <a
                className={cx('btn', 'btn-wide', styles.profileHeaderCTA)}
                target="_blank"
                rel="noopener noreferrer"
                href={calendarLink}
                onClick={openCalendarClick(name)}
              >
                <span className={cx(styles.long)}>
                  Grab coffee with {name} ☕️ 
                </span>
                <span className={cx(styles.short)}>
                  Meet 📆
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProfileHeader;
